<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <template>
          <v-card>
            <v-tabs centered dark icons-and-text>
              <v-tabs-slider></v-tabs-slider>

              <v-tab>
                Programas
                <v-icon>mdi-phone</v-icon>
              </v-tab>

              <v-tab>
                Proyectos
                <v-icon>mdi-heart</v-icon>
              </v-tab>

              <v-tab>
                Acciones
                <v-icon>mdi-account-box</v-icon>
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :headers="code4.headers"
                      :items="code4.desserts"
                      :search="code4.search"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.name != null ? item.name : "" }}</td>
                          <td>{{ item.calories }}</td>
                          <td>{{ item.fat }}</td>
                          <td>{{ item.carbs }}</td>
                          <td>{{ item.protein }}</td>
                          <td>
                            <v-btn
                              class="ma-2"
                              small
                              tile
                              outlined
                              color="indigo"
                            >
                              <v-icon left>mdi-pencil</v-icon> Actualizar
                            </v-btn>
                            <v-btn
                              class="ma-2"
                              small
                              tile
                              outlined
                              color="indigo"
                            >
                              <v-icon left>mdi-eye</v-icon> Ver
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :headers="code4.headers"
                      :items="code4.desserts"
                      :search="code4.search"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.name != null ? item.name : "" }}</td>
                          <td>{{ item.calories }}</td>
                          <td>{{ item.fat }}</td>
                          <td>{{ item.carbs }}</td>
                          <td>{{ item.protein }}</td>
                          <td>
                            <v-btn
                              class="ma-2"
                              small
                              tile
                              outlined
                              color="indigo"
                            >
                              <v-icon left>mdi-pencil</v-icon> Actualizar
                            </v-btn>
                            <v-btn
                              class="ma-2"
                              small
                              tile
                              outlined
                              color="indigo"
                            >
                              <v-icon left>mdi-eye</v-icon> Ver
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <v-data-table
                      :headers="code4.headers"
                      :items="code4.desserts"
                      :search="code4.search"
                    >
                      <template v-slot:item="{ item }">
                        <tr>
                          <td>{{ item.name != null ? item.name : "" }}</td>
                          <td>{{ item.calories }}</td>
                          <td>{{ item.fat }}</td>
                          <td>{{ item.carbs }}</td>
                          <td>{{ item.protein }}</td>
                          <td>
                            <v-btn
                              class="ma-2"
                              small
                              tile
                              outlined
                              color="indigo"
                            >
                              <v-icon left>mdi-pencil</v-icon> Actualizar
                            </v-btn>
                            <v-btn
                              class="ma-2"
                              small
                              tile
                              outlined
                              color="indigo"
                            >
                              <v-icon left>mdi-eye</v-icon> Ver
                            </v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </template>
        <template> </template>
        <template> </template>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data() {
    return {
      code4: {
        html: `<v-card>
                <v-card-title>
                  Nutrition
                  <v-spacer></v-spacer>
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="desserts"
                  :search="search"
                ></v-data-table>
              </v-card>`,
        js: ` export default {
                data () {
                  return {
                    search: '',
                    headers: [
                      {
                        text: 'Dessert (100g serving)',
                        align: 'left',
                        sortable: false,
                        value: 'name',
                      },
                      { text: 'Calories', value: 'calories' },
                      { text: 'Fat (g)', value: 'fat' },
                      { text: 'Carbs (g)', value: 'carbs' },
                      { text: 'Protein (g)', value: 'protein' },
                      { text: 'Iron (%)', value: 'iron' },
                    ],
                    desserts: [
                      {
                        name: 'Frozen Yogurt',
                        calories: 159,
                        fat: 6.0,
                        carbs: 24,
                        protein: 4.0,
                        iron: '1%',
                      },
                      {
                        name: 'Ice cream sandwich',
                        calories: 237,
                        fat: 9.0,
                        carbs: 37,
                        protein: 4.3,
                        iron: '1%',
                      },
                      {
                        name: 'Eclair',
                        calories: 262,
                        fat: 16.0,
                        carbs: 23,
                        protein: 6.0,
                        iron: '7%',
                      },
                      {
                        name: 'Cupcake',
                        calories: 305,
                        fat: 3.7,
                        carbs: 67,
                        protein: 4.3,
                        iron: '8%',
                      },
                      {
                        name: 'Gingerbread',
                        calories: 356,
                        fat: 16.0,
                        carbs: 49,
                        protein: 3.9,
                        iron: '16%',
                      },
                      {
                        name: 'Jelly bean',
                        calories: 375,
                        fat: 0.0,
                        carbs: 94,
                        protein: 0.0,
                        iron: '0%',
                      },
                      {
                        name: 'Lollipop',
                        calories: 392,
                        fat: 0.2,
                        carbs: 98,
                        protein: 0,
                        iron: '2%',
                      },
                      {
                        name: 'Honeycomb',
                        calories: 408,
                        fat: 3.2,
                        carbs: 87,
                        protein: 6.5,
                        iron: '45%',
                      },
                      {
                        name: 'Donut',
                        calories: 452,
                        fat: 25.0,
                        carbs: 51,
                        protein: 4.9,
                        iron: '22%',
                      },
                      {
                        name: 'KitKat',
                        calories: 518,
                        fat: 26.0,
                        carbs: 65,
                        protein: 7,
                        iron: '6%',
                      },
                    ],
                  }
                },
              }`,
        search: "",
        headers: [
          {
            text: "Nombre",
            align: "left",
            sortable: false,
            value: "name"
          },
          { text: "Año", value: "calories" },
          { text: "Departamento", value: "fat" },
          { text: "Presupuesto", value: "carbs" },
          { text: "Ejecutado", value: "protein" },
          { text: "Acciones", value: "iron" }
        ],
        desserts: [
          {
            name: "Frozen Yogurt",
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: "1%"
          },
          {
            name: "Ice cream sandwich",
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: "1%"
          },
          {
            name: "Eclair",
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: "7%"
          },
          {
            name: "Cupcake",
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: "8%"
          },
          {
            name: "Gingerbread",
            calories: 356,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
            iron: "16%"
          },
          {
            name: "Jelly bean",
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: "0%"
          },
          {
            name: "Lollipop",
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: "2%"
          },
          {
            name: "Honeycomb",
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: "45%"
          },
          {
            name: "Donut",
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: "22%"
          },
          {
            name: "KitKat",
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: "6%"
          }
        ]
      }
    };
  },
  components: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Cooperacion Sur - Sur" },
      { title: "Comixta" }
    ]);
  }
};
</script>
